import React from 'react'
import { useAuth } from '../../../context/AuthContext'
import RoleOffice from '../../../components/offices/RoleOffice'
import DistrictDesigntaion from './DistrictDesigntaion'
import SchoolDesignation from './SchoolDesignation'

function Designation() {
    return (
        <>
            <RoleOffice roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} element={<DistrictDesigntaion />} />
            <RoleOffice roles={["Headmaster"]} element={<SchoolDesignation />} />
        </>
    )
}

export default Designation
