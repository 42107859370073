import React, { useEffect, useState } from 'react'
import Loading from '../../components/loading/Loading'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'

function SMSManagement() {
	const auth = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [message, setMessage] = useState("")
	const [variant, setVariant] = useState("success")

	const [orders, setOrders] = useState([])

	const ordersColumns = [
		{
			name: "S/N",
			selector: (row, index) => index + 1,
			grow: 0
		},
		{
			name: "Staff Name",
			selector: row => row.staff,
			sortable: true
		},
		{
			name: "SMS Quantity",
			selector: row => row.sms_count,
			sortable: true
		},
		{
			name: "Unit Cost",
			selector: row => row.unit_cost,
			sortable: true
		},
		{
			name: "Total Cost",
			selector: row => row.cost,
			sortable: true
		},
		{
			name: "Purchase Date",
			selector: row => row.date_purchased,
			sortable: true
		},
		{
			name: "Status",
			selector: row => row.status,
			sortable: true
		},
		{
			name: "Action",
			cell: row => (<div className="d-flex flex-wrap align-items-center justify-content-between">
				<button 
					className="btn btn-sm btn-primary rounded-0 fw-bold me-2"
					onClick={() => handleOrder(row.id, "completed")}
				>
						Approve
					</button>
				<button 
					className="btn btn-sm btn-primary rounded-0 fw-bold"
					onClick={() => handleOrder(row.id, "rejected")}
				>
						Reject
					</button>
			</div>),
			sortable: false,
			grow: 1.5
		}
	]

	const fetchOrders = async () => {
		setIsLoading(true)

		try {
			const response = await fetch(`${BASE_API_URL}/sms/purchase/orders`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant("warning")
			}
			else {
				setOrders(data.orders)
			}
		}
		catch (error) {
			setMessage("Failed to fetch SMS orders. Please try again.")
			setVariant("danger")
		}
		finally {
			setIsLoading(false)
		}
	}

	const handleOrder = async (orderId, status) => {
		setIsLoading(true)
		setMessage("")

		try {
			const response = await fetch(`${BASE_API_URL}/sms/purchase/orders/${orderId}/${status}`, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant("warning")
			}
			else {
				setMessage(data.message)
				setVariant("success")
				fetchOrders()
			}
		}
		catch (error) {
			setMessage("Failed to handle order. Please try again.")
			setVariant("warning")
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchOrders()
	}, [])

	return (
		<div className='pt-2'>
			<p>
				<span className='h3 fw-bold text-muted'>SMS Management</span>
				<br />
				<span>Manage SMS prices and purchase order</span>
			</p>

			{
				message &&
				<div className={`alert alert-${variant} p-2`} role="alert">
					{message}
				</div>
			}

			{
				isLoading &&
				<div className="text-center">
					<Loading />
				</div>
			}

			<div className="mb-3">
				<p className="h5 fw-bold text-muted">SMS Orders</p>
				<DataTable
					columns={ordersColumns}
					data={orders}
					noDataComponent={"No pending SMS orders."}
				/>
			</div>

			<div className="mb-3">
				<p className="h5 fw-bold text-muted">SMS Prices</p>
			</div>
		</div>
	)
}

export default SMSManagement
