import React from 'react'
import './SideBar.css'
import { useNavigate } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/mssis.png'
import { useAuth } from '../../context/AuthContext'
import RoleOffice from '../offices/RoleOffice'
import LevelSplit from '../offices/LevelSplit'

function SideBar() {
	const auth = useAuth()
	const user = auth.user
	const navigate = useNavigate()

	const logout = () => {
		auth.logout()
		navigate('/login')
	}

	return (
		<>
			<div className="l-navbar" id="nav-bar">
				<nav className="nav">
					<div>
						<Link to="/" className="nav_logo">
							<img className='img nav_logo-img' src={logo} alt="logo" />
							<span className="nav_logo-name">MSSIS</span>
						</Link>
						<div className="nav_list">
							<NavLink to="/" className="nav_link">
								<i className={`bi bi-speedometer2 nav_icon`}></i>
								<span className="nav_name">Dashboard</span>
							</NavLink>
						</div>
						<RoleOffice roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} element={
							<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-schools`} aria-expanded="false" aria-controls="collapseExample">
								<NavLink to="schools" className='nav_link' style={{ zIndex: -1 }}>
									<i className={`bi bi-buildings nav_icon`}></i>
									<span className="nav_name">
										Schools
									</span>
								</NavLink>
								<div className="collapse nav_sub" id={`collapse-schools`}>
									<NavLink to="schools/list" className="nav_sub_link">
										<span className='nav_name'>
											Registered Schools
										</span>
									</NavLink>
									<RoleOffice roles={["Admin"]} reverse={true} element={
										<NavLink to="/schools/facilities" className="nav_sub_link">
											<span className='nav_name'>
												All Schools Facilities
											</span>
										</NavLink>
									} />
								</div>
							</div>}
						/>
						<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-members`} aria-expanded="false" aria-controls="collapseExample">
							<NavLink to="academics" className='nav_link' style={{ zIndex: -1 }}>
								<i className={`bi bi-book-half nav_icon`}></i>
								<span className="nav_name">
									Academics
								</span>
							</NavLink>
							<div className="collapse nav_sub" id={`collapse-members`}>
								<RoleOffice roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Academic Master"]} element={
									<>
										<NavLink to="/academics/subjects" className="nav_sub_link">
											<span className='nav_name'>
												Subjects
											</span>
										</NavLink>
										<NavLink to="/academics/classes" className="nav_sub_link">
											<span className='nav_name'>
												Classes
											</span>
										</NavLink>
										<RoleOffice
											roles={["Admin"]}
											reverse={true}
											element={
												<NavLink to="/academics/grades" className="nav_sub_link">
													<span className='nav_name'>
														Grades
													</span>
												</NavLink>
											} />
									</>}
								/>
								<NavLink to="/academics/exams" className="nav_sub_link">
									<span className='nav_name'>
										Exams
									</span>
								</NavLink>
								<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} element={
									<NavLink to="/teaching/learning-materials" className="nav_sub_link">
										<span className='nav_name'>
											T/L Materials
										</span>
									</NavLink>
								} />
							</div>
						</div>
						<RoleOffice roles={["Headmaster", "Academic Master", "Teacher", "Temporary Teacher"]} element={
							<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-teaching`} aria-expanded="false" aria-controls="collapseExample">
								<NavLink to="teaching" className='nav_link' style={{ zIndex: -1 }}>
									<i className={`bi bi-easel-fill nav_icon`}></i>
									<span className="nav_name">
										Teaching
									</span>
								</NavLink>
								<div className="collapse nav_sub" id={`collapse-teaching`}>
									<NavLink to="/teaching/classes" className="nav_sub_link">
										<span className='nav_name'>
											Classes
										</span>
									</NavLink>
									<NavLink to="/teaching/results" className="nav_sub_link">
										<span className='nav_name'>
											Exam Results Upload
										</span>
									</NavLink>
									<NavLink to="/teaching/learning-materials" className="nav_sub_link">
										<span className='nav_name'>
											T/L Materials
										</span>
									</NavLink>
								</div>
							</div>}
						/>
						<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]} element={
							<div className="nav_list">
								<NavLink to="/students" className="nav_link">
									<i className={`bi bi-people-fill nav_icon`}></i>
									<span className="nav_name">
										<LevelSplit primary={"Pupils"} ordinary={"Students"} />
									</span>
								</NavLink>
							</div>}
						/>
						<RoleOffice roles={["Headmaster"]} element={
							<div className="nav_list">
								<NavLink to="/staffs/school" className="nav_link">
									<i className={`bi bi-person-rolodex nav_icon`}></i>
									<span className="nav_name">Staffs</span>
								</NavLink>
							</div>}
						/>
						<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO"]} element={
							<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-staff`} aria-expanded="false" aria-controls="collapseExample">
								<NavLink to="staffs" className='nav_link' style={{ zIndex: -1 }}>
									<i className={`bi bi-person-rolodex nav_icon`}></i>
									<span className="nav_name">
										Staffs
									</span>
								</NavLink>
								<div className="collapse nav_sub" id={`collapse-staff`}>
									<NavLink to="/staffs/school" className="nav_sub_link">
										<span className='nav_name'>
											School Staffs
										</span>
									</NavLink>
									<NavLink to="/staffs/dseo" className="nav_sub_link">
										<span className='nav_name'>
											<LevelSplit primary={"DPPEO"} ordinary={"DSEO"} /> Office Staffs
										</span>
									</NavLink>
								</div>
							</div>
						} />

						<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]} element={
							<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-admin`} aria-expanded="false" aria-controls="collapseExample">
								<NavLink to="administration" className='nav_link' style={{ zIndex: -1 }}>
									<i className={`bi bi-building nav_icon`}></i>
									<span className="nav_name">
										Administration
									</span>
								</NavLink>
								<div className="collapse nav_sub" id={`collapse-admin`}>
									<RoleOffice roles={["Headmaster"]} element={
										<>
											<NavLink to="/administration/details" className="nav_sub_link">
												<span className='nav_name'>
													School Facilities
												</span>
											</NavLink>
											<NavLink to="/administration/logo-stamps" className="nav_sub_link">
												<span className='nav_name'>
													Logos and Stamps
												</span>
											</NavLink>
										</>
									} />
									<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} element={
										<>
											<NavLink to="/administration/title" className="nav_sub_link">
												<span className='nav_name'>
													Exam Results Title
												</span>
											</NavLink>
										</>
									} />
									<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]} element={
										<>
											<NavLink to="/administration/designation" className="nav_sub_link">
												<span className='nav_name'>
													Staff Designation
												</span>
											</NavLink>
											<NavLink to="/administration/announcements" className="nav_sub_link">
												<span className='nav_name'>
													Announcements
												</span>
											</NavLink>
										</>
									} />
								</div>
							</div>}
						/>

						<RoleOffice roles={["Admin"]} element={
							<>
								<div className="nav_list">
									<NavLink to="/users" className="nav_link">
										<i className={`bi bi-person-square nav_icon`}></i>
										<span className="nav_name">Users</span>
									</NavLink>
								</div>
								<div className="nav_list">
									<NavLink to="/locations" className="nav_link">
										<i className={`bi bi-geo-alt-fill nav_icon`}></i>
										<span className="nav_name">Locations</span>
									</NavLink>
								</div>
								<div className="nav_list">
									<NavLink to="/sms" className="nav_link">
										<i className={`bi bi-chat-right-text-fill nav_icon`}></i>
										<span className="nav_name">SMS</span>
									</NavLink>
								</div>
							</>}
						/>
					</div>
					<span className="nav_link cursor" onClick={logout}>
						<i className='bi bi-box-arrow-left nav_icon'></i>
						<span className="nav_name">Logout</span>
					</span>
				</nav>
			</div>
		</>
	)
}

export default SideBar
