import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'

function StaffCount() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('')

    const [staffsCount, setStaffsCount] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row?.school?.name || "Total",
            sortable: true,
            grow: 2,
        },
        {
            name: 'Female',
            selector: row => row.female,
            sortable: true
        },
        {
            name: 'Male',
            selector: row => row.male,
            sortable: true
        },
        {
            name: 'Total',
            selector: row => row.total,
            sortable: true,
            grow: 2,
        },
    ]

    const fetchStaffsCount = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/count/staffs`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage("Failed to fetch staffs count. Please, try again.")
                    setVariant("danger")
                }
            }

            setStaffsCount(data.count)
        }
        catch (error) {
            setMessage("Something went wrong. Please, try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaffsCount()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Staffs Count</p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={staffsCount}
                            highlightOnHover
                        />
                }
            </div>
        </div>
    )
}

export default StaffCount
