import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import StudentsCountHeader from '../../components/dashboard/StudentsCountHeader'
import StudentsCountContent from '../../components/dashboard/StudentsCountContent'
import LevelSplit from '../../components/offices/LevelSplit'

function StudentsCount() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('')

    const [studentsCount, setStudentsCount] = useState([])

    const columns_ordinary = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school?.name || "Total",
            sortable: true,
            grow: 2,
        },
        {
            name: <StudentsCountHeader name={"Form I"} />,
            cell: row => <StudentsCountContent f={row?.form_1?.female} m={row?.form_1?.male} t={row?.form_1?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Form II"} />,
            cell: row => <StudentsCountContent f={row?.form_2?.female} m={row?.form_2?.male} t={row?.form_2?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Form III"} />,
            cell: row => <StudentsCountContent f={row?.form_3?.female} m={row?.form_3?.male} t={row?.form_3?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Form IV"} />,
            cell: row => <StudentsCountContent f={row?.form_4?.female} m={row?.form_4?.male} t={row?.form_4?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Total"} />,
            cell: row => <StudentsCountContent f={row?.total?.female} m={row?.total?.male} t={row?.total?.total} />,
            grow: 3,
        }
    ]

    const columns_primary = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school?.name || "Total",
            sortable: true,
            grow: 2,
        },
        {
            name: <StudentsCountHeader name={"Class I"} />,
            cell: row => <StudentsCountContent f={row?.class_1?.female} m={row?.class_1?.male} t={row?.class_1?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Class II"} />,
            cell: row => <StudentsCountContent f={row?.class_2?.female} m={row?.class_2?.male} t={row?.class_2?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Class III"} />,
            cell: row => <StudentsCountContent f={row?.class_3?.female} m={row?.class_3?.male} t={row?.class_3?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Class IV"} />,
            cell: row => <StudentsCountContent f={row?.class_4?.female} m={row?.class_4?.male} t={row?.class_4?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Class V"} />,
            cell: row => <StudentsCountContent f={row?.class_5?.female} m={row?.class_5?.male} t={row?.class_5?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Class VI"} />,
            cell: row => <StudentsCountContent f={row?.class_6?.female} m={row?.class_6?.male} t={row?.class_6?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Class VII"} />,
            cell: row => <StudentsCountContent f={row?.class_7?.female} m={row?.class_7?.male} t={row?.class_7?.total} />,
            grow: 3,
        },
        {
            name: <StudentsCountHeader name={"Total"} />,
            cell: row => <StudentsCountContent f={row?.total?.female} m={row?.total?.male} t={row?.total?.total} />,
            grow: 3,
        }
    ]

    const fetchStudentCount = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const resp = await fetch(`${BASE_API_URL}/count/students`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await resp.json()
            if (!resp.ok) {
                if (resp.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('danger')
                }
            }
            else {
                setStudentsCount(data.count)
            }
        }
        catch (error) {
            setMessage('Something went wrong. Please, try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStudentCount()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                <LevelSplit primary={"Pupils"} ordinary={"Students"} /> Count
            </p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={auth.user.level === "PRIMARY" ? columns_primary : columns_ordinary}
                            data={studentsCount}
                            highlightOnHover
                            responsive
                        />
                }
            </div>
        </div>
    )
}

export default StudentsCount
