import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../components/offices/RoleOffice'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'
import LevelSplit from '../../components/offices/LevelSplit'

function ViewStaff() {
    const auth = useAuth()
    const params = useParams()
    const staffId = params.id
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [staff, setStaff] = useState({})
    const [showDialog, setShowDialog] = useState(false)

    const subject_columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Class',
            selector: row => row.form,
            sortable: true
        },
        {
            name: 'Start Date',
            selector: row => row.date_started,
            sortable: true
        },
        {
            name: 'End Date',
            selector: row => row.date_ended,
            sortable: true
        }
    ]

    const fetchStaff = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees/${staffId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStaff(data.employee)
            }
        }
        catch (error) {
            setMessage('Failed to fetch staff. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const confirmDelete = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees/${staffId}`, {
                method: 'DELETE',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                navigate('/staffs/school')
            }
        }
        catch (error) {
            setMessage('Failed to delete staff. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
            setShowDialog(false)
        }
    }

    useEffect(() => {
        fetchStaff()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Staff Details</p>
                <RoleOffice roles={["DSEO", "DSAO", "DPPEO", "DPPAO", "Headmaster"]} element={
                    <div>
                        <Link to={`/staffs/${staffId}/edit`} className='btn btn-primary btn-sm fw-bold rounded-0 me-2'>
                            <span className="me-2">Edit</span>
                            <i className='bi bi-pencil-square'></i>
                        </Link>
                        <button
                            className='btn btn-danger btn-sm fw-bold rounded-0'
                            onClick={() => setShowDialog(true)}
                        >
                            <span className="me-2">Delete</span>
                            <i className='bi bi-trash'></i>
                        </button>
                    </div>}
                />
                <ConfirmationDialog
                    open={showDialog}
                    onClose={() => {
                        setShowDialog(false)
                        setIsLoading(false)
                    }}
                    onConfirm={confirmDelete}
                    isLoading={isLoading}
                    title='Delete Staff'
                    message={`Are you sure you want to delete ${staff.firstname} ${staff.lastname}?`}
                />
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        <div className='shadow p-2 mb-4'>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            {
                                staff.profile && (
                                    <div className='mb-4 text-center'>
                                        <img
                                            src={`${BASE_API_URL}${staff.profile}`}
                                            alt='profile'
                                            className='rounded-circle'
                                            width='150'
                                            height='150'
                                        />
                                    </div>
                                )
                            }

                            <div className="row">
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Full Name</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.firstname} {staff.middlename} {staff.lastname}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Email</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.email}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Phone</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.phone}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Date of Birth</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.dob}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Sex</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.sex}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Education Level</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.education_level}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>College Attended</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.college_attended}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Year of Studies Completion</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.year_of_completion}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Region of Domicile</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.residence_region}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>District of Domicile</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.residence_district}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Check Number</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.check_number}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>TSC Number</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.tsc_number}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Working Station</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {
                                                staff.school ? (
                                                    staff.school.name
                                                ) : (
                                                    staff.role
                                                )
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Designation</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {
                                                staff.school ? (
                                                    staff.school.role === "Headmaster" ?
                                                        (
                                                            staff.sex === "Female" ? "Headmistress" : "Headmaster"
                                                        )
                                                        :
                                                        staff.school.role === "Academic Master" ?
                                                            (
                                                                staff.sex === "Female" ? "Academic Mistress" : "Academic Master"
                                                            )
                                                            :
                                                            staff.school.role
                                                ) : (
                                                    staff.role
                                                )
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Date of Designation</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.designation_date}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Salary Level</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.salary_level}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Account Status</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.status}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Employment Date</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.employment_date}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Confirmation Date</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.confirmation_date}
                                        </span>
                                    </p>
                                </div>
                                <LevelSplit primary={<></>} ordinary={
                                    <div className="col-lg-6">
                                        <p>
                                            <span className='fw-bold'>Core Teaching Subjects</span>
                                            <br />
                                            <span className='text-muted fs-5'>
                                                {
                                                    staff.subject_one && <span>{staff.subject_one.name}</span>
                                                }
                                                {
                                                    staff.subject_two && <span>, {staff.subject_two.name}</span>
                                                }
                                                {
                                                    staff.subject_three && <span>, {staff.subject_three.name}</span>
                                                }
                                                {
                                                    staff.subject_four && <span>, {staff.subject_four.name}</span>
                                                }
                                            </span>
                                        </p>
                                    </div>
                                } />
                            </div>
                        </div>

                        {
                            staff.role !== "DSEO" &&
                            <div className='shadow p-2 mb-4'>
                                <p className='h5 fw-bold text-muted mb-3'>Subjects Taught</p>
                                <div className='mb-3'>
                                    <DataTable
                                        columns={subject_columns}
                                        data={staff.subjects}
                                        highlightOnHover
                                        pagination
                                        paginationPerPage={perPage}
                                        onChangePage={page => setCurrentPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        noDataComponent={"No subjects taught by this staff."}
                                    />
                                </div>
                            </div>
                        }
                    </>
            }
        </div>
    )
}

export default ViewStaff
