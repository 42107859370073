/*
After selecting an exam, select all the subjects and their forms/classes present in the exam 
that the teacher teaches
*/

import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../components/offices/RoleOffice'

function TeachingResultsSubjects() {
    const auth = useAuth()
    const params = useParams()
    const exam_id = params.exam_id

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        ["DSEO", "DSAO", "DSSLO"].includes(auth.user.role) &&
        {
            name: 'School',
            selector: row => row.school.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Subject',
            selector: row => row.subject.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Form/Class',
            selector: row => row.form.name,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <Link
                to={`/teaching/results/upload/${row.exam_subject_id}`}
                state={{
                    data: {
                        ...row,
                        exam
                    }
                }}
                className='btn btn-sm btn-primary rounded-0'>
                Select
            </Link>,
            sortable: false,
        },
    ]

    const [exam, setExam] = useState({})
    const [subjects, setSubjects] = useState([])
    const [filteredSubjects, setFilteredSubjects] = useState([])
    const [filterSchool, setFilterSchool] = useState('')
    const [filterSubject, setFilterSubject] = useState('')
    const [filterForm, setFilterForm] = useState('')

    const handleFilter = () => {
        let data = subjects
        if (filterSchool) {
            data = data.filter(item => item.school.name.toLowerCase().includes(filterSchool.toLowerCase()))
        }
        if (filterSubject) {
            data = data.filter(item => item.subject.name.toLowerCase().includes(filterSubject.toLowerCase()))
        }
        if (filterForm) {
            data = data.filter(item => item.form.name.toLowerCase() === filterForm.toLowerCase())
        }
        setFilteredSubjects(data)
    }

    const fetchExamSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${exam_id}/teacher/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setSubjects(data.subjects)
                setFilteredSubjects(data.subjects)
                setExam(data.exam)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exams. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExamSubjects()
    }, [])

    useEffect(() => {
        handleFilter()
    }, [filterSchool, filterSubject, filterForm])

    return (
        <div className='pt-2'>
            <div className="mb-3">
                <p className='h3 fw-bold text-muted'>Select Exam Subject</p>
            </div>
            <div>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    (
                        <>
                            <div className="body shadow p-2 mb-3">
                                <p className='h4 fw-bold text-muted'>Exam Details</p>
                                <div className=''>
                                    <p className='text-muted'>
                                        Exam Name: <span className='fw-bold'>{exam.name}</span>
                                    </p>
                                    <p className='text-muted'>
                                        Exam Type: <span className='fw-bold'>{exam.type}</span>
                                    </p>
                                    <p className='text-muted'>
                                        Start Date: <span className='fw-bold'>{exam.start_date}</span>
                                    </p>
                                    <p className='text-muted'>
                                        End Date: <span className='fw-bold'>{exam.end_date}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="mb-3">
                                <p className="text-muted fw-bold">Filter Exam Subjects</p>
                                <div className="row mx-0">
                                    <RoleOffice 
                                        roles={["DSEO", "DSAO", "DSSLO"]}
                                        element={
                                            <div className='col-12 col-lg-3 mb-2'>
                                            <input
                                                type="text"
                                                className="form-control rounded-0"
                                                placeholder="Filter school..."
                                                onChange={e => setFilterSchool(e.target.value)}
                                            />
                                            </div>
                                        }
                                    />
                                    <div className='col-12 col-lg-3 mb-2'>
                                        <input
                                            type="text"
                                            className="form-control rounded-0"
                                            placeholder="Filter subject..."
                                            onChange={e => setFilterSubject(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-lg-3 mb-2'>
                                        <select
                                            className="form-select rounded-0"
                                            placeholder="Filter form/class..."
                                            onChange={e => setFilterForm(e.target.value)}
                                        >
                                            <option value="">Filter form/class...</option>
                                            <option value="Form I">Form 1</option>
                                            <option value="Form II">Form 2</option>
                                            <option value="Form III">Form 3</option>
                                            <option value="Form Iv">Form 4</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                                        <button
                                            className="btn btn-primary rounded-0"
                                            onClick={handleFilter}
                                        >
                                            <span className="me-2">Filter</span>
                                            <i className='bi bi-funnel-fill'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                                <div>
                                    <DataTable
                                        columns={columns}
                                        data={filteredSubjects}
                                        highlightOnHover
                                        pagination
                                        paginationPerPage={perPage}
                                        onChangePage={page => setCurrentPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        noDataComponent={"No subjects found."}
                                    />
                                </div>
                            </>
                            )
            }
                        </div>
            )
}

            export default TeachingResultsSubjects
