
/*
download template and fill it with student student scores,
then upload it back here
*/

import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { saveAs } from 'file-saver'

function TeachingResultsUpload() {
    const auth = useAuth()
    const params = useParams()
    const exam_subject_id = params.exam_subject_id
    const location = useLocation()
    const data = location.state.data
    const exam = data.exam
    const school = data.school    

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [uploadErrors, setUploadErrors] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [file, setFile] = useState(null)
    const [previousScores, setPreviousScores] = useState([])
    const [searchScores, setSearchScores] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [enabledRow, setEnabledRow] = useState('')
    const [updatedScore, setUpdatedScore] = useState('')
    const [visibility, setVisibility] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])

    const handleDelete = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/exams/results/scores/${exam_subject_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ students: selectedRows })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
                setSelectedRows([])
                fetchPreviousScores()
            }
        }
        catch (error) {
            setMessage('Failed to delete scores. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Student Name',
            selector: row => `${row.student.firstname} ${row.student.middlename} ${row.student.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Sex',
            selector: row => row.student.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Score',
            selector: row => <div>
                {
                    enabledRow === row.id ?
                        (
                            <form
                                onSubmit={e => handleScoreChange(e, row)}
                                onBlur={e => handleScoreChange(e, row)}
                            >
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    value={updatedScore}
                                    autoFocus
                                    onChange={e => setUpdatedScore(e.target.value)}
                                />
                            </form>
                        ) :
                        (
                            <span>{row.score}</span>
                        )
                }
            </div>,
            grow: 0,
            sortable: true,
        },
        {
            name: 'Grade',
            selector: row => row.grade,
            sortable: true,
        },
        {
            name: 'Date Uploaded',
            selector: row => row.date_uploaded,
            grow: 2,
            sortable: true,
        },
        {
            name: <>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="mb-1 me-2">
                        Action
                    </div>
                    <div className="mb-1">
                        {/* select all */}
                        <input
                            type='checkbox'
                            className='form-check-input rounded-0 fs-5'
                            checked={selectedRows.length === previousScores.length}
                            onChange={() => {
                                if (selectedRows.length === previousScores.length) {
                                    setSelectedRows([])
                                }
                                else {
                                    setSelectedRows(previousScores.map(score => score.id))
                                }
                            }}
                        />
                    </div>
                </div>
            </>,
            cell: row => <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="mb-1 me-2">
                    {
                        enabledRow === row.id ?
                            (
                                <button
                                    className='btn btn-primary rounded-0'
                                    onClick={e => handleScoreChange(e, row)}
                                    disabled={isLoading}
                                >
                                    <i className='bi bi-check-lg'></i>
                                </button>
                            ) :
                            (
                                <button
                                    className='btn btn-primary rounded-0'
                                    onClick={() => {
                                        setEnabledRow(row.id)
                                        setUpdatedScore(row.score)
                                    }}
                                    disabled={isLoading}
                                >
                                    <i className='bi bi-pencil'></i>
                                </button>
                            )
                    }
                </div>
                <div className="mb-1">
                    {
                        selectedRows.includes(row.id) ?
                            <button
                                className='btn btn-primary rounded-0'
                                onClick={() => setSelectedRows(selectedRows.filter(id => id !== row.id))}
                            >
                                Selected
                            </button> :
                            <button
                                className='btn btn-outline-primary rounded-0'
                                onClick={() => setSelectedRows([...selectedRows, row.id])}
                            >
                                Select
                            </button>
                    }
                </div>
            </div>,
            grow: 2,
        }
    ]

    const handleSearch = () => {
        setSearchScores(previousScores.filter(score => {
            const student = `${score.student.firstname} ${score.student.middlename} ${score.student.lastname}`
            return student.toLowerCase().includes(searchTerm.toLowerCase())
        }))
    }

    const updateScore = async (score_id, score) => {
        try {
            const response = await fetch(`${BASE_API_URL}/exams/results/scores/${score_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ score })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                const new_score = data.score
                const new_grade = data.grade
                // update previous scores, 
                // avoid making another fetch request caused by calling fetchPreviousScores()
                const updateScoresDisplayed = (scores, score_id, new_score, new_grade) => {
                    return scores.map(score => {
                        if (score.id === score_id) {
                            return {
                                ...score,
                                score: new_score,
                                grade: new_grade
                            };
                        }
                        return score;
                    });
                };

                setPreviousScores(prevScores => updateScoresDisplayed(prevScores, score_id, new_score, new_grade));
                setSearchScores(prevScores => updateScoresDisplayed(prevScores, score_id, new_score, new_grade));
            }
        }
        catch (error) {
            setMessage("Failed to update score")
            setVariant("warning")
        }
    }

    const handleScoreChange = async (e, row) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        // validate updated score
        if (updatedScore.length < 1) {
            setMessage("Updated score cannot be empty")
            setVariant("warning")
        }
        else if (isNaN(updatedScore)) {
            setMessage("Enter a valid score");
            setVariant("warning")
        }
        else if (updatedScore < 0 || updatedScore > 100) {
            setMessage("Score must be between 0 and 100");
            setVariant("warning")
        }
        else if (updatedScore === row.score) {
            setMessage("No changes made to score");
            setVariant("warning")
        }
        else {
            await updateScore(row.id, updatedScore)
        }

        setIsLoading(false)
        setEnabledRow('')
        return
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('Please wait while data is being processed.')
        setVariant('info')
        setUploadErrors([])

        const formData = new FormData()
        formData.append('file', file)

        try {
            const response = await fetch(`${BASE_API_URL}/exams/results/${exam_subject_id}`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
                setUploadErrors(data.errors)
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchPreviousScores()
            }
        }
        catch (error) {
            setMessage('An error occurred while uploading file. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchPreviousScores = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/exams/results/${exam_subject_id}`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setPreviousScores(data.results)
                setSearchScores(data.results)
                setVisibility(data.visibility)
            }
        }
        catch (error) {
            setMessage('An error occurred while fetching previous scores. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleVisibilityChange = async (checked) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/exams/results/${exam_subject_id}/visibility`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ visibility: checked ? 'Internal' : 'Private' })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setVisibility(data.visibility)
            }
        }
        catch (error) {
            setMessage("Failed to change visibility")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const downloadTemplate = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/results/${exam_subject_id}/template?school=${school.id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            const blob = await response.blob()
            const filename = `MSSIS-${school.name}-${data.form.name}-${data.subject.name}-${exam.name}-scores.xlsx`
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to export students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPreviousScores()
    }, [])

    useEffect(() => {
        handleSearch()
    }, [searchTerm])

    return (
        <div className='pt-2'>
            <div className="mb-3">
                <p className='h3 fw-bold text-muted'>Upload Subject Scores</p>
            </div>
            <div>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
            </div>

            <div className="body shadow p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Name: <span className='fw-bold'>{exam.name}</span>
                    </p>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam.end_date}</span>
                    </p>
                    <p className="text-muted">
                        Form/Class: <span className='fw-bold'>{data.form.name}</span>
                    </p>
                    <p className="text-muted">
                        Subject: <span className='fw-bold'>{data.subject.name}</span>
                    </p>
                </div>
            </div>

            <div className="body shadow p-2 mb-4">
                <p className="mb-1">
                    Download template and fill it with student scores,
                    then upload it back here.
                </p>
                <div className="text-end mb-3">
                    <button
                        className='btn btn-primary rounded-0 mb-3'
                        onClick={downloadTemplate}
                    >
                        <span className='me-2'>Download Template</span>
                        <i className='bi bi-download'></i>
                    </button>
                </div>

                <div className='mb-3'>
                    <div>
                        {
                            message &&
                            <>
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                                <div className="my-2">
                                    {
                                        uploadErrors && uploadErrors.map((error, index) => (
                                            <div key={index} className='alert alert-danger p-2 my-1'>
                                                {error}
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <form encType='multipart/form-data' onSubmit={handleUpload}>
                        <input
                            type='file'
                            className='form-control mb-3 rounded-0'
                            accept='.xlsx, .xls'
                            required
                            disabled={isLoading}
                            onChange={e => setFile(e.target.files[0])}
                        />
                        <div className="text-end">
                            <button className='btn btn-primary rounded-0 px-4' disabled={isLoading}>
                                {
                                    isLoading ?
                                        <>Uploading... <Loading /></> :
                                        <>
                                            <span className="me-2">
                                                Upload
                                            </span>
                                            <i className='bi bi-upload'></i>
                                        </>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="body shadow p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Uploaded Scores</p>
                {
                    visibility &&
                    <div className="row mb-2">
                        <p className='col'>
                            Visibility: <span className='fw-bold'>{visibility.value}</span>
                            <br />
                            {visibility.message}
                        </p>
                        {
                            isLoading ?
                                (
                                    <span>
                                        Changin visibility... <Loading />
                                    </span>
                                ) :
                                (
                                    <form className='col-12 col-lg-auto'>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="visibility"
                                                // once results are public subject teacher cant return them to private or internal
                                                disabled={isLoading || visibility.value === 'Public'}
                                                checked={visibility.value === 'Internal'}
                                                onChange={e => handleVisibilityChange(e.target.checked)}
                                            />
                                            <label className="form-check-label" htmlFor="visibility">
                                                Make Internal
                                            </label>
                                        </div>
                                    </form>
                                )
                        }
                    </div>
                }
                <p>
                    Tap or click on the score to enable editing.
                    Click elsewhere to finish editing.
                </p>
                <DataTable
                    title={
                        <div className="row mx-0">
                            <div className="col-12 col-lg-9 mb-1 pe-1 ps-0 mx-0">
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    placeholder='Search scores...'
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className='col-12 col-lg-auto ms-auto mb-1'>
                                <button
                                    className='btn btn-danger rounded-0'
                                    onClick={handleDelete}
                                    disabled={isLoading || selectedRows.length < 1}
                                >
                                    <i className='bi bi-trash'></i>
                                    <span className='ms-2'>
                                        {
                                            isLoading ? <>Deleting... <Loading /></> : "Delete Selected"
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    }
                    columns={columns}
                    data={searchScores}
                    onRowClicked={row => {
                        setEnabledRow(row.id)
                        setUpdatedScore(row.score)
                    }}
                    highlightOnHover
                    pagination
                    paginationPerPage={perPage}
                    onChangePage={page => setCurrentPage(page)}
                    onChangeRowsPerPage={perPage => setPerPage(perPage)}
                    noDataComponent={"No scores uploaded yet."}
                />
            </div>
        </div>
    )
}

export default TeachingResultsUpload
