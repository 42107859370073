import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { Link } from 'react-router-dom'

function Profile() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [staff, setStaff] = useState({})
    const [image, setImage] = useState('')
    const [sign, setSign] = useState('')

    const subject_columns = [
        {
            name: 'S/N',
            cell: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Class',
            selector: row => row.form,
            sortable: true
        },
        {
            name: 'Start Date',
            selector: row => row.date_started,
            sortable: true
        },
        {
            name: 'End Date',
            selector: row => row.date_ended,
            sortable: true
        }
    ]

    const fetchStaff = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees/${auth.user.id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStaff(data.employee)
                setImage(data.employee.profile)
                setSign(data.employee.signature)
            }
        }
        catch (error) {
            setMessage('Failed to fetch staff. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const updateProfilePic = async (e) => {
        e.preventDefault()
        setMessage('')

        const formData = new FormData()
        formData.append('profile_picture', image)

        try {
            const response = await fetch(`${BASE_API_URL}/profile/picture`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token,
                },
                body: formData,
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
                auth.updateProfile(data.profile)
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
    }

    const updateSignature = async (e) => {
        e.preventDefault()
        setMessage('')

        const formData = new FormData()
        formData.append('sign', sign)

        try {
            const response = await fetch(`${BASE_API_URL}/profile/signature`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token,
                },
                body: formData,
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
    }

    useEffect(() => {
        fetchStaff()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Your Details</p>
                <Link to={`/settings/password`} className='btn btn-primary btn-sm fw-bold rounded-0'>
                    <span className="me-2">Update Password</span>
                    <i className='bi bi-lock'></i>
                </Link>
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        <div className='shadow p-2 mb-4'>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <div className='my-3 text-center'>
                                {
                                    image ? (
                                        image.toString().startsWith("/") ? (
                                            <img
                                                src={`${BASE_API_URL}${image}`}
                                                alt='profile'
                                                className='rounded-circle'
                                                width='150'
                                                height='150'
                                            />
                                        ) : (
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt='profile'
                                                className='rounded-circle'
                                                width='150'
                                                height='150'
                                            />
                                        )
                                    ) : (
                                        <i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
                                    )
                                }
                                <form className='mt-3 row text-center' onSubmit={updateProfilePic}>
                                    <div className='col col-lg-4 mb-2 ms-auto'>
                                        <input
                                            className='form-control rounded-0'
                                            type='file'
                                            id='formFile'
                                            placeholder='Upload Image'
                                            accept='image/*'
                                            onChange={(e) => setImage(e.target.files[0])}
                                            required
                                        />
                                    </div>
                                    <div className='col col-lg-1 mb-3 me-auto'>
                                        <button type='submit' className='btn btn-primary px-4 fw-bold rounded-0'>
                                            Upload
                                        </button>
                                    </div>
                                </form>
                            </div>


                            <div className="row">
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Full Name</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.firstname} {staff.middlename} {staff.lastname}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Email</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.email}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Phone</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.phone}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Date of Birth</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.dob}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Sex</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.sex}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Education Level</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.education_level}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Check Number</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.check_number}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>TSC Number</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.tsc_number}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Working Station</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {
                                                staff.school ? (
                                                    staff.school.name
                                                ) : (
                                                    staff.role
                                                )
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Designation</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {
                                                staff.school ? (
                                                    staff.school.role === "Headmaster" ?
                                                        (
                                                            staff.sex === "Female" ? "Headmistress" : "Headmaster"
                                                        )
                                                        :
                                                        staff.school.role === "Academic Master" ?
                                                            (
                                                                staff.sex === "Female" ? "Academic Mistress" : "Academic Master"
                                                            )
                                                            :
                                                            staff.school.role
                                                ) : (
                                                    staff.role
                                                )
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Salary Level</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.salary_level}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Account Status</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.status}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Employment Date</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.date_created}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Confirmation Date</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {staff.confirmation_date}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span className='fw-bold'>Core Teaching Subjects</span>
                                        <br />
                                        <span className='text-muted fs-5'>
                                            {
                                                staff.subject_one && <span>{staff.subject_one.name}</span>
                                            }
                                            {
                                                staff.subject_two && <span>, {staff.subject_two.name}</span>
                                            }
                                            {
                                                staff.subject_three && <span>, {staff.subject_three.name}</span>
                                            }
                                            {
                                                staff.subject_four && <span>, {staff.subject_four.name}</span>
                                            }
                                        </span>
                                    </p>
                                </div>
                                {
                                    auth.user.class_teacher && auth.user.class_teacher.length > 0 &&
                                    <div className="col-lg-6">
                                        <p>
                                            <span className='fw-bold'>Classes You are Class Teacher</span>
                                            <br />
                                            <span className='text-muted fs-5'>
                                                {
                                                    auth.user.class_teacher.map((ct, index) => (
                                                        <span key={index} className='me-2'>{ct},</span>
                                                    ))
                                                }
                                            </span>
                                        </p>
                                    </div>
                                }
                            </div>

                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <div>
                                <p className="fw-bold text-dark">Upload Signature</p>
                                <form className='row mx-0' onSubmit={updateSignature}>
                                    <div className="col-12 col-lg-4 mb-2 border border-primary rounded-0 p-0">
                                        {
                                            sign ? (
                                                sign.toString().startsWith("/") ? (
                                                    <img
                                                        src={`${BASE_API_URL}${sign}`}
                                                        alt='signature'
                                                        className='img-fluid'
                                                        width='90'
                                                        height='30'
                                                    />
                                                ) : (
                                                    <img
                                                        src={URL.createObjectURL(sign)}
                                                        alt='signature'
                                                        className='img-fluid'
                                                        width='90'
                                                        height='30'
                                                    />
                                                )
                                            ) : (
                                                <div className='m-2'>No Signature</div>
                                            )
                                        }
                                    </div>
                                    <div className='col-12 col-lg-6 mb-2'>
                                        <input
                                            className='form-control rounded-0'
                                            type='file'
                                            id='signature'
                                            placeholder='Upload Signature'
                                            accept='image/*'
                                            onChange={(e) => setSign(e.target.files[0])}
                                            required
                                        />
                                    </div>
                                    <div className='col-12 col-lg-2 mb-2 text-end'>
                                        <button type='submit' className='btn btn-primary px-4 rounded-0 fw-bold'>
                                            Upload
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {
                            staff.role !== "DSEO" &&
                            <div className='shadow p-2 mb-4'>
                                <p className='h5 fw-bold text-muted mb-3'>Subjects You Teach</p>
                                <div className='mb-3'>
                                    <DataTable
                                        columns={subject_columns}
                                        data={staff.subjects}
                                        highlightOnHover
                                        pagination
                                        paginationPerPage={perPage}
                                        onChangePage={page => setCurrentPage(page)}
                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                        noDataComponent={"No subjects taught by this staff."}
                                    />
                                </div>
                            </div>
                        }
                    </>
            }
        </div >
    )
}

export default Profile
