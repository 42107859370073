import { Outlet, Route, Routes } from 'react-router-dom'
import './App.css'
import { AuthProvider } from './context/AuthContext'
import Home from './pages/Home'
import Login from './pages/Login'
import Schools from './pages/school/Schools'
import Dashboard from './pages/dashboard/Dashboard'
import NewSchool from './pages/school/NewSchool'
import ViewSchool from './pages/school/ViewSchool'
import EditSchool from './pages/school/EditSchool'
import Staffs from './pages/staff/Staffs'
import NewStaff from './pages/staff/NewStaff'
import ViewStaff from './pages/staff/ViewStaff'
import EditStaff from './pages/staff/EditStaff'
import HeadmasterSchool from './pages/school/HeadmasterSchool'
import RoleRedirect from './components/offices/RoleRedirect'
import Subjects from './pages/subject/Subjects'
import NewSubject from './pages/subject/NewSubject'
import ViewSubject from './pages/subject/ViewSubject'
import NotFound from './components/notfound/NotFound'
import StaffSchool from './pages/school/StaffSchool'
import Classes from './pages/class/Classes'
import NewClass from './pages/class/NewClass'
import SubjectTeachers from './pages/subject/SubjectTeachers'
import Students from './pages/student/Students'
import NewStudents from './pages/student/NewStudents'
import ViewStudent from './pages/student/ViewStudent'
import Grades from './pages/grades/Grades'
import NewGrades from './pages/grades/NewGrades'
import EditGrades from './pages/grades/EditGrades'
import ViewClass from './pages/class/ViewClass'
import NewSchoolSubject from './pages/subject/NewSchoolSubject'
import TeachingClasses from './pages/teaching/TeachingClasses'
import Exams from './pages/exams/Exams'
import NewExam from './pages/exams/NewExam'
import TeachingResultsExams from './pages/teaching/TeachingResultsExams'
import TeachingResultsSubjects from './pages/teaching/TeachingResultsSubjects'
import TeachingResultsUpload from './pages/teaching/TeachingResultsUpload'
import ViewExam from './pages/exams/ViewExam'
import OverallResults from './pages/results/OverallResults'
import ExternalResults from './pages/results/ExternalResults'
import InternalResults from './pages/results/InternalResults'
import Takwimu from './pages/administration/Takwimu'
import Facilities from './pages/school/Facilities'
import Profile from './pages/settings/Profile'
import PasswordUpdate from './pages/settings/PasswordUpdate'
import PasswordReset from './pages/settings/PasswordReset'
import PasswordResetNew from './pages/settings/PasswordResetNew'
import SchoolDesignation from './pages/administration/designation/SchoolDesignation'
import StudentsCount from './pages/dashboard/StudentsCount'
import StaffCount from './pages/dashboard/StaffCount'
import CreateResultsTitle from './pages/administration/CreateResultsTitle'
import SingleFacility from './pages/school/SingleFacility'
import EnrolStudents from './pages/subject/EnrolStudents'
import TeachingClassStudents from './pages/teaching/TeachingClassStudents'
import ExamCandidates from './pages/exams/ExamCandidates'
import ExamCandidatesRegister from './pages/exams/ExamCandidatesRegister'
import OverallTenStudents from './pages/results/OverallTenStudents'
import StudentsSubjectWise from './pages/results/StudentsSubjectWise'
import NewLocation from './pages/locations/NewLocation'
import Locations from './pages/locations/Locations'
import EditExam from './pages/exams/EditExam'
import OverallStudentRanking from './pages/results/OverallStudentRanking'
import OverallSubjectRanking from './pages/results/OverallSubjectRanking'
import TeachersRanking from './pages/results/TeachersRanking'
import TeachersSubjectWise from './pages/results/TeachersSubjectWise'
import SchoolRanking from './pages/results/SchoolRanking'
import StudentDiscipline from './pages/discipline/StudentDiscipline'
import ReportDetails from './pages/report/ReportDetails'
import NewReportDetails from './pages/report/NewReportDetails'
import TeachingLearningMaterials from './pages/teaching/TeachingLearningMaterials'
import SubjectTopicCoverage from './pages/teaching/subject-topic-coverage/SubjectTopicCoverage'
import TeachersLogbook from './pages/teaching/teachers-logbook/TeachersLogbook'
import NewSubjectTopicCoverage from './pages/teaching/subject-topic-coverage/NewSubjectTopicCoverage'
import ViewSubjectTopics from './pages/teaching/subject-topic-coverage/ViewSubjectTopics'
import ViewSubTopics from './pages/teaching/subject-topic-coverage/ViewSubTopics'
import UpdateTopicCoverage from './pages/teaching/subject-topic-coverage/UpdateTopicCoverage'
import TopicCoverageReports from './pages/teaching/subject-topic-coverage/TopicCoverageReports'
import BareSubjectTopics from './pages/teaching/subject-topic-coverage/BareSubjectTopics'
import BareSubjectTopic from './pages/teaching/subject-topic-coverage/BareSubjectTopic'
import ApproveSubjectTopicCoverage from './pages/teaching/subject-topic-coverage/ApproveSubjectTopicCoverage'
import LogoAndStamps from './pages/administration/LogoAndStamps'
import SubjectLogBook from './pages/teaching/teachers-logbook/SubjectLogBook'
import NewSubjectTopic from './pages/teaching/subject-topic-coverage/NewSubjectTopic'
import NewSubTopic from './pages/teaching/subject-topic-coverage/NewSubTopic'
import Settings from './pages/settings/Settings'
import Announcements from './pages/administration/Announcements'
import SMSManagement from './pages/sms/SMSManagement'
import Designation from './pages/administration/designation/Designation'
import DSEOStaff from './pages/staff/DSEOStaff'
import NewBareClass from './pages/class/NewBareClass'

function App() {
	return (
		<AuthProvider>
			<Routes>
				<Route path="/" element={<Home />}>
					<Route path="/" element={<Outlet />}>
						<Route index element={<Dashboard />} />
						<Route path="/dashboard/students" element={<StudentsCount />} />
						<Route path="/dashboard/staffs" element={<StaffCount />} />
					</Route>

					<Route path="/schools" element={
						<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} children={<Outlet />} />
					}>
						<Route path='list' element={<Schools />} />
						<Route path=":id" element={<ViewSchool />} />
						<Route path="new" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} children={<NewSchool />} />
						} />
						<Route path=":id/edit" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} children={<EditSchool />} />
						} />
						<Route path=":id/headmaster" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} children={<HeadmasterSchool />} />
						} />
						<Route path=":id/staff" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} children={<StaffSchool />} />
						} />

						<Route path="facilities" element={<Outlet />}>
							<Route index element={<Facilities />} />
							<Route path=":name" element={<SingleFacility />} />
						</Route>
					</Route>

					<Route path="/staffs" element={
						<RoleRedirect
							roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]}
							children={<Outlet />}
						/>
					}>
						<Route path="school" element={<Staffs />} />
						<Route path=":id" element={<ViewStaff />} />
						<Route path="new" element={<NewStaff />} />
						<Route path=":id/edit" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]}
								children={<EditStaff />}
							/>
						} />
						<Route path="dseo" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]}
								children={<DSEOStaff />}
							/>
						} />
					</Route>

					<Route path="/academics" element={<Outlet />}>
						<Route path="subjects" element={
							<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Academic Master"]} children={<Outlet />} />
						}>
							<Route index element={<Subjects />} />
							<Route path="new" element={<NewSubject />} />
							<Route path="new/school" element={<NewSchoolSubject />} />
							<Route path=":id" element={<ViewSubject />} />
							<Route path=":id/edit" element={<Subjects />} />
							<Route path=":id/assign-teachers" element={<SubjectTeachers />} />
							{/* TODO: role redirect applies here */}
							<Route path=":id/enrol" element={<EnrolStudents />} />
						</Route>

						<Route path="classes" element={
							<RoleRedirect
								roles={[
									"Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO",
									"Headmaster", "Academic Master"
								]}
								children={<Outlet />}
							/>
						}>
							<Route index element={<Classes />} />
							<Route path="new" element={<NewClass />} />
							<Route path="new/bare" element={<NewBareClass />} />
							<Route path=":id" element={<ViewClass />} />
						</Route>

						<Route path="grades" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Academic Master"]}
								children={<Outlet />}
							/>
						}>
							<Route index element={<Grades />} />
							<Route path="new" element={<NewGrades />} />
							<Route path=":id/edit" element={<EditGrades />} />
						</Route>

						<Route path="exams" element={<Outlet />}>
							<Route index element={<Exams />} />
							<Route path="new" element={<NewExam />} />
							<Route path=":exam_id" element={<Outlet />}>
								<Route index element={<ViewExam />} />
								<Route path="edit" element={<EditExam />} />
								<Route path="candidates" element={<Outlet />}>
									<Route index element={<ExamCandidates hideOptions={false} purpose={"candidates"} />} />
									<Route path="register" element={<ExamCandidatesRegister />} />
								</Route>
								<Route path="report" element={<Outlet />}>
									<Route index element={<ReportDetails />} />
									<Route path="new" element={<NewReportDetails />} />
								</Route>
							</Route>
						</Route>

						<Route path="results" element={<Outlet />}>
							<Route path=":exam_id/internal" element={<InternalResults />} />
							<Route path=":exam_id/internal/:form_id" element={<Outlet />}>
								<Route index element={<OverallResults />} />
								<Route path="overall-ten" element={<OverallTenStudents />} />
								<Route path="subjectwise" element={<StudentsSubjectWise />} />
								<Route path="reports" element={<ExamCandidates hideOptions={true} purpose={"reports"} />} />
								<Route path="sms" element={<ExamCandidates hideOptions={true} purpose={"sms"} />} />
							</Route>
							<Route path=":exam_id/external" element={<ExternalResults />} />
							<Route path=":exam_id/external/:form_id" element={<Outlet />}>
								<Route index element={<OverallResults />} />
								<Route path="overall-ten" element={<OverallTenStudents />} />
								<Route path="subjectwise" element={<StudentsSubjectWise />} />
								<Route path="overall-ranks" element={<OverallStudentRanking />} />
								<Route path="subject-ranks" element={<OverallSubjectRanking />} />
								<Route path="teacher-ranks" element={<TeachersRanking />} />
								<Route path="subjectwise-teachers" element={<TeachersSubjectWise />} />
								<Route path="school-ranks" element={<SchoolRanking />} />
								<Route path="reports" element={<ExamCandidates hideOptions={true} purpose={"reports"} />} />
								<Route path="sms" element={<ExamCandidates hideOptions={true} purpose={"sms"} />} />
							</Route>
						</Route>

						<Route path="discipline" element={<Outlet />}>
							<Route path="student/:exam_id" element={<StudentDiscipline />} />
						</Route>
					</Route>

					<Route path="/teaching" element={<Outlet />}>
						<Route path="classes" element={
							<RoleRedirect roles={["Headmaster", "Academic Master", "Teacher", "Temporary Teacher"]} children={<Outlet />} />
						}>
							<Route index element={<TeachingClasses />} />
							<Route path='students' element={<TeachingClassStudents />} />
						</Route>
						<Route path="results" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "Headmaster", "Academic Master", "Teacher", "Temporary Teacher"]} children={<Outlet />} />
						}>
							<Route index element={<TeachingResultsExams />} />
							{/* id => selected exam id */}
							<Route path=":exam_id/" element={<TeachingResultsSubjects />} />
							{/* page where results are uploaded */}
							<Route path="upload/:exam_subject_id/" element={<TeachingResultsUpload />} />
						</Route>

						<Route path="learning-materials" element={<Outlet />}>
							<Route index element={<TeachingLearningMaterials />} />
							<Route path="subject-topic-coverage" element={<Outlet />}>
								<Route index element={<SubjectTopicCoverage />} />
								<Route path="view" element={
									<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} children={<Outlet />} />
								}>
									<Route index element={<BareSubjectTopics />} />
									<Route path=":topicId" element={<Outlet />}>
										<Route index element={<BareSubjectTopic />} />
										<Route path="new" element={<NewSubTopic />} />
									</Route>
									<Route path="new" element={<NewSubjectTopic />} />
								</Route>
								<Route path="new" element={
									<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO"]} children={<NewSubjectTopicCoverage />} />
								} />
								<Route path="report" element={<TopicCoverageReports type={"district"} />} />
								<Route path="view/:formId/:subjectId" element={<Outlet />}>
									<Route index element={<ViewSubjectTopics />} />
									<Route path=":topicId" element={<ViewSubTopics />} />
									<Route path=":topicId/:subtopicId" element={<UpdateTopicCoverage />} />
									<Route path="report" element={<TopicCoverageReports type={"subject"} />} />
								</Route>
								<Route path="approve" element={
									<RoleRedirect roles={["Headmaster", "Academic Master", "Teacher"]} children={<ApproveSubjectTopicCoverage />} />
								} />
							</Route>
							<Route path="teachers-logbook" element={<Outlet />}>
								<Route index element={<TeachersLogbook />} />
								<Route path=":formId/:subjectId" element={<SubjectLogBook />} />
							</Route>
						</Route>
					</Route>

					<Route path="/students" element={<Outlet />}>
						<Route index element={<Students />} />
						<Route path=":id" element={<ViewStudent />} />
						<Route path="new" element={
							<RoleRedirect roles={["Headmaster", "Academic Master"]} children={<NewStudents />} />}
						/>
					</Route>

					<Route path="/administration" element={<Outlet />}>
						<Route path='details' element={
							<RoleRedirect roles={["Headmaster"]} children={<Takwimu />} />}
						/>
						<Route path='designation' element={
							<RoleRedirect roles={["DSEO", "DSAO", "DPPEO", "DPPAO", "Headmaster"]} children={<Designation />} />}
						/>
						<Route path='title' element={
							<RoleRedirect roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} children={<CreateResultsTitle />} />}
						/>
						<Route path='logo-stamps' element={
							<RoleRedirect roles={["Headmaster"]} children={<LogoAndStamps />} />}
						/>
						<Route path='announcements' element={
							<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]} children={<Announcements />} />}
						/>
					</Route>

					<Route path="/settings" element={<Outlet />}>
						<Route index element={
							<RoleRedirect 
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster"]} 
								children={<Settings />} 
							/>
						} />
						<Route path="profile" element={<Profile />} />
						<Route path="password" element={<PasswordUpdate />} />
					</Route>

					<Route path="/locations" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<Locations />} />
						<Route path="new" element={<NewLocation />} />
					</Route>

					{/* users are different from employees/staff */}
					<Route path="/users" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<NewStaff />} />
					</Route>

					{
						/* SMS management, different for SMS settings in user accounts. 
						Here we set price, approve orders and other SMS administrative tasks. */
					}
					<Route path="/sms" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<SMSManagement />} />
					</Route>

					<Route path="*" element={<NotFound />} />
				</Route>

				{/* merge these into single component, there's some unnecessarily repeatetive code */}
				<Route path="/login" element={<Login />} />
				<Route path="/password-reset" element={<PasswordReset />} />
				<Route path="/password-reset-new" element={<PasswordResetNew />} />
			</Routes>
		</AuthProvider>
	)
}

export default App
