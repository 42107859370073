import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import ResultsTitle from '../../components/results/ResultsTitle'
import { useReactToPrint } from 'react-to-print'
import { saveAs } from 'file-saver'

function TeachersRanking() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location.state.exam
    const form = location.state.form

    const contentRef = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [school, setSchool] = useState(null)
    const [results, setResults] = useState([])

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-Overall Teachers Ranking`,
        removeAfterPrint: true,
    })

    const handleExport = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            let url = `${BASE_API_URL}/results/external/export/${exam.id}/${form.id ? form.id : form}/teacher-rank`

            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant("warning")
                return
            }
            
            let suff = "MSSIS"
			if (auth.user.school) {
				suff = auth.user.school.name
			}

			const filename = `${suff} ${exam?.name} Overall Teachers Ranking ${new Date().toISOString().split('T')[0]}.pdf`

			const blob = await response.blob()
			saveAs(blob, filename)
        }
        catch(error) {
            setMessage("Failed to export results to PDF. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchResults = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = ``
            if (exam.type === "External") {
                url = `${BASE_API_URL}/results/external/analysis/${exam.id}/${form.id ? form.id : form}/teacher-rank`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setResults(data.ranks)
                setSchool(data.school)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam results. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3 text-end">
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        // handlePrint(null, () => contentRef.current)
                        handleExport()
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <div className="mb-3">
                                <ResultsTitle exam={exam} form={form} school={school} title={"OVERALL TEACHERS RANKING"} />
                            </div>
                            <div className="mb-3">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                                        <thead>
                                            <tr className='align-middle'>
                                                <th rowSpan={2}>S/N</th>
                                                <th rowSpan={2}>TEACHER'S NAME</th>
                                                <th rowSpan={2}>SCHOOL</th>
                                                <th rowSpan={2}>SUBJECT</th>
                                                <th colSpan={results.grades ? results.grades.length + 1 : 1}>PERFORMANCE (GRADES)</th>
                                                <th rowSpan={2}>GPA</th>
                                                <th rowSpan={2}>PASS (A - D)</th>
                                                <th rowSpan={2}>RANK</th>
                                            </tr>
                                            <tr className='align-middle'>
                                                {
                                                    results.grades && results.grades.map((grade, index) => (
                                                        <React.Fragment key={index}>
                                                            <th>{grade.grade}</th>
                                                        </React.Fragment>
                                                    ))
                                                }
                                                <th>TOT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                results.teachers && results.teachers.map((teacher, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className='text-start'>{teacher.name.toUpperCase()}</td>
                                                        <td className='text-start'>{teacher.school.toUpperCase()}</td>
                                                        <td className='text-start'>{teacher.subject.toUpperCase()}</td>
                                                        {
                                                            results.grades && results.grades.map((grade, i) => (
                                                                <td key={i}>{teacher[grade.grade]}</td>
                                                            ))
                                                        }
                                                        <td>{teacher["T"]}</td>
                                                        <td>{teacher.gpa}</td>
                                                        <td>{teacher.percent}</td>
                                                        <td>{teacher.rank}</td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                results.bottom_summary &&
                                                <tr className="fw-bold">
                                                    <td colSpan={4} className="bg-info">RESULTS' SUMMARY</td>
                                                    {
                                                        results.grades && results.grades.map((grade, i) => (
                                                            <td key={i} className="bg-info">{results.bottom_summary[grade.grade]}</td>
                                                        ))
                                                    }
                                                    <td className="bg-info">{results.bottom_summary["T"]}</td>
                                                    <td className="bg-info">{results.bottom_summary["gpa"]}</td>
                                                    <td className="bg-info">{results.bottom_summary["percent"]}</td>
                                                    <td className="bg-info"></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default TeachersRanking
