import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import RoleOffice from '../../components/offices/RoleOffice'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'

function ViewExam() {
    const auth = useAuth()
    const params = useParams()
    const exam_id = params.exam_id
    const location = useLocation()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [exam, setExam] = useState(location.state.exam)
    const [showDialog, setShowDialog] = useState(false)

    const fetchExam = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${exam_id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setExam(data.exam)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const confirmDelete = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/exams/${exam_id}`, {
                method: 'DELETE',
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                navigate('/academics/exams')
            }
        }
        catch (error) {
            setMessage('Failed to delete exam. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
            setShowDialog(false)
        }
    }

    const handleVisibilityChange = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/exams/visibility/${exam.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ visibility: exam.visibility === "Public" ? "Private" : "Public" })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setExam({
                    ...exam,
                    visibility: data.visibility.value
                })
                setMessage(data.visibility.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Failed to publish exam. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExam()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>{exam.name}</p>
                <div className="d-flex flex-wrap align-items-center">
                    {
                        ((exam.type == "Internal" && auth.user.role != "DSEO") || (exam.type == "External" && auth.user.role == "DSEO")) &&
                        <RoleOffice roles={["DSEO", "DSAO", "Headmaster", "Academic Master"]} element={
                            <button
                                className='btn btn-danger btn-sm fw-bold rounded-0 mb-2 me-2'
                                onClick={() => setShowDialog(true)}
                            >
                                <span className="me-2">Delete</span>
                                <i className='bi bi-trash'></i>
                            </button>}
                        />
                    }
                    {
                        (
                            (exam.type == "Internal" && ["Headmaster", "Academic Master"].includes(auth.user.school ? auth.user.school.role : ""))
                            || (exam.type == "External" && ["DSEO", "DSAO"].includes(auth.user.role))
                        ) &&
                        <>
                            <button
                                className='btn btn-primary btn-sm fw-bold rounded-0 mb-2 me-2'
                                onClick={() => handleVisibilityChange()}
                            >
                                <span className="me-2">
                                    {
                                        exam.visibility == "Public" ? "Unpublish" : "Publish"
                                    }
                                </span>
                                {
                                    exam.visibility == "Public" ?
                                        <i className='bi bi-eye-slash'></i>
                                        :
                                        <i className='bi bi-eye'></i>
                                }
                            </button>
                            <Link
                                to={`/academics/exams/${exam_id}/edit`}
                                state={{ exam }}
                                // disable editing until it is fully complete and bug free
                                className={`btn btn-primary btn-sm fw-bold rounded-0 mb-2 me-2 ${isLoading ? 'disabled' : ''} disabled`}
                            >
                                <span className="me-2">Edit</span>
                                <i className='bi bi-pencil'></i>
                            </Link>
                        </>
                    }
                    <RoleOffice roles={["Headmaster"]} element={
                        <Link
                            to={`/academics/exams/${exam_id}/report`}
                            state={{ exam }}
                            // disable editing until it is fully complete and bug free
                            className={`btn btn-primary btn-sm fw-bold rounded-0 mb-2 ${isLoading ? 'disabled' : ''}`}
                        >
                            <span className="me-2">Report Details</span>
                            <i className='bi bi-file-earmark-text-fill'></i>
                        </Link>
                    } />
                </div>
                <ConfirmationDialog
                    open={showDialog}
                    onClose={() => {
                        setShowDialog(false)
                        setIsLoading(false)
                    }}
                    onConfirm={confirmDelete}
                    isLoading={isLoading}
                    title='Delete Exam'
                    message={`Are you sure you want to delete ${exam.name}?`}
                />
            </div>
            <div className="body shadow p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam.end_date}</span>
                    </p>
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    (
                        <div className='text-center my-2'>
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <>
                            {
                                exam.type === "External" &&
                                <div className="body shadow p-2 mb-3">
                                    <p className='h4 fw-bold text-muted'>
                                        Participating Schools ({exam?.participants?.length})
                                    </p>
                                    <div className='row'>
                                        {
                                            exam.participants && exam.participants.map(school => (
                                                <div className="col-auto mb-2" key={school.id}>
                                                    <span className='text-primary'>
                                                        {school.name} Secondary School
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            <div className="body shadow p-2 mb-3">
                                <p className='h4 fw-bold text-muted'>
                                    Classes and Subjects Involved ({Object.keys(exam?.subjects)?.length})
                                </p>
                                {
                                    exam.subjects && Object.keys(exam.subjects).map((form, index) => (
                                        <div className='mb-2 border rounded-0 p-2' key={index}>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <p className='fw-bold'>
                                                    {form} ({exam?.subjects[form]?.length})
                                                </p>
                                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                    <Link
                                                        to={`/academics/discipline/student/${exam_id}`}
                                                        state={{ form: form, exam: exam }}
                                                        className='btn btn-primary btn-sm fw-bold rounded-0 mb-2 me-2'
                                                    >
                                                        <span className='me-2'>Discipline Scores</span>
                                                        <i className='bi bi-star-half'></i>
                                                    </Link>
                                                    <Link
                                                        to={`candidates`}
                                                        state={{ form }}
                                                        className='btn btn-primary btn-sm fw-bold rounded-0 mb-2'
                                                    >
                                                        <span className='me-2'>Registered Candidates</span>
                                                        <i className='bi bi-person-fill-check'></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                {
                                                    exam.subjects[form].map(subject => (
                                                        <div className="col-auto col-lg-2 m-2" key={subject.id}>
                                                            <span className='text-dark'>{subject.name}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            {
                                exam.grades && exam.grades.length > 0 &&
                                <div className="body shadow p-2 mb-3">
                                    <p className='h4 fw-bold text-muted'>Grades Used</p>
                                    <div className="row mx-0">
                                        {
                                            exam.grades.map(grade => (
                                                <div key={grade.id} className="col-12 col-lg-3 m-2 px-3 py-2 border rounded-0">
                                                    <p className='fs-3 fw-bold'>{grade.grade} ({grade.points})</p>
                                                    <div className='fs-5 fw-bold d-flex align-items-center justify-content-between'>
                                                        <span className='me-2'>Min Score: {grade.min_score}</span>
                                                        <span className={`badge bg-${grade.indicator} rounded-0`}>
                                                            {grade.remarks}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    )
            }

        </div>
    )
}

export default ViewExam
