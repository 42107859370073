import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import RoleOffice from '../../components/offices/RoleOffice'
import Loading from '../../components/loading/Loading'

function DSEOStaff() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [fetchedStaffs, setFetchedStaffs] = useState([])

    const columns = [
		{
			name: 'S/N',
			selector: (row, index) => index + 1,
			sortable: false,
			grow: 0,
		},
		{
			name: 'Name',
			selector: row => `${row?.firstname} ${row?.lastname}`,
			grow: 2,
			sortable: true,
		},
		{
			name: 'Check Number',
			selector: row => row?.check_number,
			sortable: false,
		},
		{
			name: 'TSC Number',
			selector: row => row?.tsc_number,
			sortable: false,
		},
		{
			name: 'Phone',
			selector: row => row?.phone,
			sortable: false,
		},
		{
			name: 'Sex',
			selector: row => row?.sex,
			sortable: true,
		},
		{
			name: 'Station',
			selector: row => row?.station,
			sortable: true,
		},
		{
			name: 'Designation',
			selector: row => row?.designation,
			sortable: true,
		},
		{
			name: 'Action',
			cell: row =>
				<div className='d-flex'>
					<RoleOffice roles={["DSEO", "DSAO", "DPPEO", "DPPAO"]} element={
						<Link
							to={`/staffs/${row?.id}/edit`}
							state={{ staff: row }}
							className='btn btn-sm btn-primary rounded-0 me-2'>
							<i className='bi bi-pencil-square fs-6 mx-1'></i>
						</Link>}
					/>
					<Link
						to={`/staffs/${row?.id}`}
						state={{ staff: row }}
						className='btn btn-sm btn-primary rounded-0'>
						<i className='bi bi-eye-fill fs-6 mx-1'></i>
					</Link>
				</div>,
			sortable: false,
		}
	]

    const fetchStaffs = async () => {
		setIsLoading(true)
		setMessage('')

		try {
			const response = await fetch(`${BASE_API_URL}/employees/dseo`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant('warning')
			}
			else {
				setFetchedStaffs(data.employees)
			}
		} catch (error) {
			setMessage('Failed to fetch staffs. Please try again.')
			setVariant('danger')
		} finally {
			setIsLoading(false)
		}
	}

    useEffect(() => {
        fetchStaffs()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>DSEO Office Staff Members</p>
                <Link to='/staffs/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                    <span className="me-2">Register</span>
                    <i className='bi bi-person-plus-fill'></i>
                </Link>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                <DataTable
                    columns={columns}
                    data={fetchedStaffs?fetchedStaffs:[]}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={"No staff members found."}
                />
            </div>
        </div>
    )
}

export default DSEOStaff
